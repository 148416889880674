import React, { useContext } from "react";

import classes from "./AdvisorsList.module.css";

import { SorterDesktop } from "../SorterDesktop/SorterDesktop";
import { SelectedFilters } from "../SelectedFilters/SelectedFilters";
import { AdvisorCard } from "../AdvisorCard";
import { SearchResultsContext } from "../../../Context/SearchResultsContext";

const isUSAFilterSelected = (filterTypes = [], selectedFilters = {}) => {
  const usaType = (filterTypes.servingCountriesType || []).find(
    (v) => v.value === "usa"
  );
  if (!usaType) {
    return false;
  }
  if (!selectedFilters.servingCountriesType) {
    return false;
  }
  return selectedFilters.servingCountriesType.some((v) => v === usaType.id);
};
export const AdvisorsList = ({
  onFilterSelected,
  onSortSelected,
  onAddToCompare = () => {},
  onRemoveFromCompare = () => {},
}) => {
  const {
    advisors,
    selectedFilters,
    filterTypes,
    trackSelectedAdvisor,
    selectedOrder,
    selectedSort,
    setSelectedOrder,
  } = useContext(SearchResultsContext);
  const showZipCodeInput = isUSAFilterSelected(
    filterTypes || [],
    selectedFilters || {}
  );
  return (
    <div className="advisor-list-border uk-width-3-4@m">
      <div className="searchResults">
        <div className={`uk-grid uk-visible@m ${classes["align-center"]}`}>
          <div className="uk-width-1-5">
            <h4 className="title-search">
              {`${advisors.length} MATCHING ADVISORS`}
            </h4>
          </div>
          <div className="uk-width-4-5@m">
            <SorterDesktop
              onSortSelected={onSortSelected}
              showZipCodeInput={showZipCodeInput}
              selectedOrder={selectedOrder}
              setSelectedOrder={setSelectedOrder}
              selectedSort={selectedSort}
            />
          </div>
        </div>
        <hr className="uk-visible@m hr-fix" />
        <SelectedFilters
          filters={selectedFilters}
          filterTypes={filterTypes}
          onFilterSelected={onFilterSelected}
        />
        <div className="cardlist">
          <div
            className="uk-child-width-1-3@m uk-child-width-1-2@s uk-grid-medium uk-grid-match uk-grid"
            uk-grid={"true"}
          >
            {advisors.map((a) => {
              return (
                <>
                  <AdvisorCard
                   selectedSort={selectedSort}
                    key={a.id}
                    {...a}
                    firmName={a.firm.name}
                    onRemoveFromCompare={onRemoveFromCompare}
                    onAddToCompare={onAddToCompare}
                    trackSelectedAdvisor={trackSelectedAdvisor}
                  />
                  
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
