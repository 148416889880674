import React from "react";
import "./donate.css";

const Donate = () => {
  return (
    <div className="uk-container partners donate-page-container">
      <div className="intro-info-container">
        <h1>Support ValuesAdvisor's Growth</h1>
        <p>
          Donations are processed through Regenerative Social Finance (RSF) and
          fully received by ValuesAdvisor, ensuring your support directly
          contributes to our mission. Your contribution is tax-deductible and
          helps us empower more investors to align their capital with their
          values. <b>Thank you for supporting our growth!</b>
        </p>
      </div>
      <div className="payment-info-containers">
        <div className="credit-card-info-container">
          <h3>Credit Card</h3>
          <p>
            Visit{" "}
            <a href="https://rsfsocialfinance.org/other-funds">
              RSF’s donation page
            </a>
            , and select ValuesAdvisor from the{" "}
            <label
              style={{
                textDecoration: "underline",
              }}
            >
              {" "}
              dropdown menu
            </label>{" "}
            (It’s the last one). Enter your donation amount.
          </p>
          <img
            src="img/donation-option-dropdown.png"
            alt="donate-options-credit-card"
          ></img>

          <div className="daf-info-container">
            <h3>For DAFs (Donor Advised Funds)</h3>
            <p >Information that you might need:</p>
            <ul>
              <li>
                Recipient Name: <b>RSF / ValuesAdvisor Fund</b>
              </li>
              <li>
                Address: <b>PO Box 2007 San Francisco CA 94126</b>
              </li>
              <li>
                Contact: <b>Alex Plowden</b>
              </li>
              <li>
                Contact Title: <b>Senior Manager, Investor & Donor Relations</b>
              </li>
              <li>
                Phone: <b>415.561.6158</b>
              </li>
              <li>
                Email: <b>alex.plowden@rsfsocialfinance.org</b>
              </li>
              <li>
                EIN (Tax ID): <b>13-6082763</b>.
              </li>
            </ul>
          </div>
        </div>
        <div className="check-info-container">
          <h3>Check</h3>
          <p>
            Make check payable to “RSF.” Write “ValuesAdvisor” in the memo
            section.
          </p>
          <p>
            Mail check to:
            <br /> ValuesAdvisor ℅ RSF <br /> PO BOX 2007 <br /> San Francisco,
            CA 94126.
          </p>
          <img
            src="img/donation-option-check.png"
            alt="donate-options-check"
          ></img>

          <div className="daf-info-container">
            <h3>ACH Wire</h3>
            <p >
              Fill out a “Contribution Transmittal Form” which can be found{" "}
              <a href="https://rsfsocialfinance.org/wp-content/uploads/2024/10/RSF-Giving-Guide-2024.pdf">
                HERE
              </a>
              .
            </p>
            <ul>
              <li>
                Receiving Bank: <b>Beneficial State Bank</b>
              </li>
              <li>
                Bank Address: <b>1438 Webster Street, Suite 100</b>
              </li>
              <li>
                ABA/Routing Number: <b>121182056</b>
              </li>
              <li>
                Beneficiary: <b>ValuesAdvisor </b>
              </li>

              <li>
                Beneficiary Account #: <b>9800005986</b>
              </li>
              <li>
                Memo: <b>For ValuesAdvisor</b>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="donation-options-container">
        <p className="donation-options ">
          If you are interested in donating in stock or cryptocurrency, please
          read more information{" "}
          <a href="https://rsfsocialfinance.org/wp-content/uploads/2024/10/RSF-Giving-Guide-2024.pdf">
            HERE
          </a>
          . If you are seeking alternative payment methods from those listed,
          please email{" "}
          <a href="mailto:info@valuesadvisor.com">info@valuesadvisor.com</a>.
        </p>
        <p className="disclaimer ">
          Charitable Gift Receipts RSF will provide a gift receipt via mail.
          RSF’s EIN is 13-6082763.
        </p>
      </div>
    </div>
  );
};

export default Donate;
