import axiosInstance from '.';

export function uploadImage(data) {
    return axiosInstance({
        method: 'post',
        url: '/upload/image',
        data,
    })
}

export function uploadFile(data) {
    return axiosInstance({
        method: 'post',
        url: '/upload/file',
        data,
    })
}

export function removeFile(data) {
    return axiosInstance({
        method: 'post',
        url: '/upload/remove',
        data,
    })
}