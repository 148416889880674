import classes from "./AdvisorCard.module.css";
import React from "react";
const SelectedSortInfo = ({ value, unit, infoText }) => {
  if(!unit && !value){
    return null;
  }
  if (Boolean(value) === false) {
    return (
      <div class={classes["selected-sort-info"]}>
      <p>
        <span></span>
        {"None"}
        <br></br> <span>{infoText}</span>
      </p>
    </div>
    );
  }
  return (
    <div class={classes["selected-sort-info"]}>
      <p>
        <span>$</span>
        {value}
        <span>{unit}</span> <br></br> <span>{infoText}</span>
      </p>
    </div>
  );
};
export const AdvisorCard = ({
  profilePic,
  name,
  firmName,
  id,
  toBeCompared,
  onAddToCompare = () => {},
  onRemoveFromCompare = () => {},
  trackSelectedAdvisor = () => {},
  teamInfo,
  firm,
  selectedSort,
}) => {
  const placeholderPicture = "http://via.placeholder.com/323x187";
  const onCompare = toBeCompared ? onRemoveFromCompare : onAddToCompare;
  
  let SelectedSortInfoProps = {
    value: 0,
    unit: "",
    infoText: "",
  };

  const minAccountSize = firm.minimumAccountSize;
  const firmUnit = firm.unit;
  const valuesAlignedAum = teamInfo.valuesAlignedAum;
  const teamInfoAum = teamInfo.aum;
  const valuesAlignedAumUnit = "%";
  const clientAccountSize = teamInfo.clientAccountSize;
  const clientAccountSizeUnit = teamInfo.clientAccountSizeUnit;

  const percentageOfValuesAligned =
    teamInfoAum > 0 ? Math.round((valuesAlignedAum / teamInfoAum) * 100) : 0;
  if (selectedSort?.value === "alignedAum") {
    SelectedSortInfoProps = {
      value: percentageOfValuesAligned,
      unit: valuesAlignedAumUnit,
      infoText: "% VA AUM",
    };
  }
  if (selectedSort?.value === "teamTypicalAccountSize") {
    SelectedSortInfoProps = {
      value: clientAccountSize,
      unit: clientAccountSizeUnit,
      infoText: "Typical Acc.Size",
    };
  }
  if (selectedSort?.value === "minimumAccountSize") {
    SelectedSortInfoProps = {
      value: minAccountSize,
      unit: firmUnit,
      infoText: "Min. Acc. Size",
    };
  }
  return (
    <div className={classes.card} data-testid="advisor-card">
      <div className={`${classes["uk-card"]} uk-card-default uk-card-body`}>
        <a
          href={`/profile/${id}`}
          onClick={(event) => {
            trackSelectedAdvisor(id);
          }}
        >
          <img
            className={classes.image}
            src={profilePic ? profilePic : placeholderPicture}
            alt=""
          />
          <SelectedSortInfo {...SelectedSortInfoProps}></SelectedSortInfo>

          <div className={classes.body}>
            <h3 className={classes.name}>{name}</h3>
            <p className={classes.firm}>{firmName}</p>
          </div>
        </a>
        <div className={classes.footer}>
          <hr className={classes.separator} />
          <button
            className={classes.btn}
            onClick={(e) => onCompare(id)}
            data-testid={`add-to-compare-button-${id}`}
          >
            <span
              uk-icon={
                toBeCompared ? "icon: minus-circle" : "icon: plus-circle"
              }
              className="uk-icon"
            />
            {`${toBeCompared ? "ADDED" : "ADD TO COMPARE"}`}
          </button>
        </div>
      </div>
    </div>
  );
};
